.footer {
    width: 100%;
    height: 7vh;
    background: #eeeeee;
    position: relative;
    color: #ffffff;
    font-size: 1.5rem;
    overflow: hidden;
}

.footer-polygon {
    position: absolute;
    right: 0;
    top: 0;
    clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
    height: 100%;
    transform-origin: right;
}

.footer-polygon:nth-child(1) {
    z-index: 10;
    background: #1c1b20;
    width: 100%;
}

.footer-polygon:nth-child(2) {
    z-index: 20;
    background: #232029;
    width: 95%;
}

.footer-textbox {
    position: absolute;
    width: 90%;
    height: 100%;
    top: 0;
    right: 5%;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-autor {
    font-family: 'Monserrat-regular';
    color: #fff;
}

.footer-icon-zone a {
    font-size: 3rem;
    color: #eeeeee;
    margin-right: 3rem;
}
.footer-icon-zone a:hover {
    font-size: 4rem;
    color: #ffffff;
    transition: all .5s;

}


@media screen and (max-width: 550px) {
    .footer{
        font-size: 1rem;
    }
    .footer-icon-zone a{
        font-size: 2rem;
    }
    .footer-icon-zone a:hover{
        font-size: 2.5rem;
    }
}
