@import url("./normalize.css");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 10px;
	font-family: "Monserrat-semiBold";
	overflow-x: hidden;
	background-color: #eee;
}

body {
	overflow-x: hidden;
	overflow: hidden;
}

@font-face {
	font-family: "Monserrat-Bold";
	src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
	font-family: "Monserrat-semiBold";
	src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
	font-family: "Monserrat-regular";
	src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
	font-family: "Monserrat-Light";
	src: url("./assets/fonts/Montserrat-Light.ttf");
}

.main-heading {
	font-size: 5rem;
	font-weight: 600;
	letter-spacing: 1rem;
}

.cta-btn {
	display: inline-block;
	font-family: "Monserrat-regular";
	font-size: 1.2rem;
	letter-spacing: 1px;
	text-decoration: none;
	padding: 1rem 4rem;
	margin-top: 5rem;
	border-radius: 0.4rem;
	box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.3);
	position: relative;
	z-index: 12000;
	cursor: pointer;
}

.crimson {
	background-color: crimson;
	color: #fff;
}

.danger {
	background-color: #f0d500;
	border: 0;
}

.violet {
	background-color: #190159;
	color: white;
}

.cta-btn::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: inherit;
	border-radius: inherit;
	z-index: -1;
	transition: all 0.5s;
}

.cta-btn:hover::after {
	transform: scaleX(1.2) scaleY(1.6);
	opacity: 0;
}

@media screen and (max-width: 760px) {
	.cta-btn {
		padding: 1rem 2.5rem;
	}

	.main-heading {
		font-size: 4rem;
	}
}

@media screen and (max-width: 550px) {
	.main-heading {
		font-size: 2.5rem;
	}

	.cta-btn {
		margin-top: 3rem;
	}
}
