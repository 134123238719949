.portfolio {
    width: 100%;
    background: #eeeeee;
    color:  #333;
    position: relative;
}

.portfolio-intro {
    width: 100%;
    height: 75vh;
    background: url('../../assets/img/portfolioBG.jpeg') center no-repeat;
    background-size: cover;
    position: relative;
}

.portfolio-intro::after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
}

.portfolio-intro-polygon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 70%;
    box-shadow: 0 30px 40px -15px rgba(0,0,0,0.35);
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    background-color: #eeeeee;
    z-index: 10;
    transform-origin: right;
}

.portfolio-intro-textbox {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5;
    color: #333;
    padding: 1rem;
    z-index: 20;
}


.portfolio-container{
    width: 100%;
}


.portfolio-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1400px) {
    .portfolio-intro-polygon {
        width: 100%;
        height: 60%;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
        transform-origin: top;
    }
}

@media screen and (max-width: 760px) {
    .portfolio-cards {
        grid-template-columns: 1fr 1fr;
    }    
}

@media screen and (max-width: 550px) {
    .portfolio-cards {
        display: flex;
        flex-direction: column;
    }
}